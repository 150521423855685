import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API,
  authDomain: "tradein-0000.firebaseapp.com",
  projectId: "tradein-0000",
  storageBucket: "tradein-0000.appspot.com",
  messagingSenderId: "482531719609",
  appId: "1:482531719609:web:096d698d29a4c0a0d59e68",
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
export { db, auth };
