import React, { useState } from "react";
import "./TradeIn.css";
import InspectionCriteria from "./Inspection";
import { db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { validEmail, validPhone, hasvalue } from "./regex";

const TradeIn = () => {
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState([
    { title: "Make", errors: false, value: "", errortext: "This field can not be empty" },
    { title: "Model", errors: false, value: "", errortext: "This field can not be empty" },
    { title: "Email", errors: false, value: "", errortext: "Please enter a valid Email" },
    { title: "Name", errors: false, value: "", errortext: "This field can not be empty" },
    { title: "Phone", errors: false, value: "", errortext: "Please enter a valid Phone Number" },
  ]);
  const [criteria, setCriteria] = useState([
    {
      title: "Power",
      question: "Does the device power up and function normally?",
      value: "", errors: false
    },
    {
      title: "Enclosure/Back Cover",
      question:
        "Is the enclosure/back cover in good condition? (Normal wear and tear is OK)",
      value: "", errors: false
    },
    {
      title: "Liquid Damage",
      question: "Has your device been in contact with water?",
      value: "", errors: false
    },
    {
      title: "Display",
      question:
        "Is the display in good condition? (Normal wear and tear is OK)",
      value: "", errors: false
    },
    {
      title: "Keys/Buttons",
      question: "Are the Keys/Buttons in good working condition?",
      value: "", errors: false
    },
  ]);


  const handleSubmit = (e) => {
    e.preventDefault()
    let emptyButtons = true;
    let emptyInput = true;
    let invalidEmail = true;
    let invalidPhone = true;

    const updatedCriteria = criteria.map((mapping) => {
      if (mapping.value === "") {
        emptyButtons = true;
      } else {
        emptyButtons = false;
      }
      return { ...mapping, errors: mapping.value === "" };
    });

    const updatedInputValues = inputValues.map((mapping) => {
      if (mapping.value === "") {
        emptyInput = true;
        mapping.errors = true;
      } else {
        emptyInput = false;
        mapping.errors = false;
      }

      if (mapping.title === 'Email') {
        if (!validEmail.test(mapping.value)) {
          invalidEmail = true;
          mapping.errors = true;
          console.log(invalidEmail);
        } else {
          invalidEmail = false;
          mapping.errors = false;
        }
      }

      if (mapping.title === 'Phone') {
        if (!validPhone.test(mapping.value)) {
          invalidPhone = true;
          mapping.errors = true;
          console.log(invalidPhone);
        } else {
          invalidPhone = false;
          mapping.errors = false;
        }
      }

      return mapping;
    });

    if (!emptyInput && !invalidEmail && !invalidPhone && !emptyButtons) {
      db.collection("tradein")
        .add({
          Date: serverTimestamp(),
          Power: updatedCriteria[0].value,
          Backcover: updatedCriteria[1].value,
          Liquid: updatedCriteria[2].value,
          Display: updatedCriteria[3].value,
          Buttons: updatedCriteria[4].value,
          Make: updatedInputValues[0].value,
          Model: updatedInputValues[1].value,
          Email: updatedInputValues[2].value,
          Name: updatedInputValues[3].value,
          Phone: updatedInputValues[4].value,
          Quoted: false
        })
        .then((docRef) => {
          alert("Data Successfully Submitted");
          window.location.reload(false);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    }
    // else {
    //   alert("Data Error");
    // }

    setCriteria(updatedCriteria);
    setInputValues(updatedInputValues);
  };

  const handleMakeChange = (e) => {

    const { name, value } = e.target;

    // if (name === "Email") {
    //   if (!validEmail.test(value)) {
    //     console.error('invalid email')
    //   }
    //   else {
    //     console.log('valid email')
    //   }
    // }
    // if (name === "Phone") {
    //   if (!validPhone.test(value)) {
    //     console.error('invalid phone')
    //   }
    //   else {
    //     console.log('valid phone')
    //   }

    // }

    inputValues.map((inputName) => {
      if (inputName.title === name) {
        inputName.value = value;
      }
    });
  };

  return (
    <div className="container">
      <form className="flex-main" onSubmit={handleSubmit}>
        <div className="input-container">
          <h2 className="h2">Your Device info</h2>
          {inputValues.map((inputName) => (
            <label className="label">
              {inputName.title}
              <input
                className="input"
                name={inputName.title}
                onChange={handleMakeChange}
              />
              {inputName.errors ? <p className="errortext">{inputName.errortext}</p> : <></>}
            </label>
          ))}

          {/* <label className="label">
            Model:{" "}
            <input
              className="input"
              name="myInput"
              onChange={(e) => setModel(e.target.value)}
            />
          </label>
          <label className="label">
            Email:{" "}
            <input
              className="input"
              name="myInput"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="label">
            Name:{" "}
            <input
              className="input"
              name="myInput"
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <label className="label">
            Phone Number:{" "}
            <input
              className="input"
              name="myInput"
              onChange={(e) => setNumber(e.target.value)}
            />
          </label> */}
          <div className="button-div">
            <button className="buttons submit">
              Submit
            </button>
          </div>
        </div>
        <InspectionCriteria criteria={criteria} setCriteria={setCriteria} />
        <button className="buttons submit bottom">
          Submit
        </button>
      </form>
    </div>
  );
};

export default TradeIn;
