import React from "react";
import "./TradeIn.css";

const InspectionCriteria = ({ criteria, setCriteria }) => {
  const handleCriteriaChange = (index, value, e) => {
    e.preventDefault()
    const newCriteria = [...criteria];
    newCriteria[index].value = value;
    setCriteria(newCriteria);
  };

  return (
    <div>
      <h2 className="h2">Your Devices Health</h2>
      <div className="card-container">
        {criteria.map((criterion, index) => (
          <div className={criterion.errors ? "card error-card" : "card"} key={index}>
            <h3>{criterion.title}</h3>
            <p>{criterion.question}</p>
            <div className="btn-container">
              <button
                className={
                  criterion.value === "Yes" ? "buttons selected" : "buttons"
                }
                onClick={(e) => handleCriteriaChange(index, "Yes", e)}>
                Yes
              </button>

              <button
                className={
                  criterion.value === "No" ? "buttons selected" : "buttons"
                }
                onClick={(e) => handleCriteriaChange(index, "No", e)}>
                No
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default InspectionCriteria;
